import { render, staticRenderFns } from "./Homeworks.vue?vue&type=template&id=bbf2bc60"
import script from "./Homeworks.vue?vue&type=script&lang=js"
export * from "./Homeworks.vue?vue&type=script&lang=js"
import style0 from "./Homeworks.vue?vue&type=style&index=0&id=bbf2bc60&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/CoreProductivity.Backoffice.Mobile/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bbf2bc60')) {
      api.createRecord('bbf2bc60', component.options)
    } else {
      api.reload('bbf2bc60', component.options)
    }
    module.hot.accept("./Homeworks.vue?vue&type=template&id=bbf2bc60", function () {
      api.rerender('bbf2bc60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/pages/homework/Homeworks.vue"
export default component.exports